import * as XLSX from "xlsx";

const createAndClickDownloadLink = (href, filename, target = "_self") => {
  const link = document.createElement("a");
  link.href = href;
  link.target = target;
  link.setAttribute("download", filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return link.href; // Return href in case we need to revoke it
};

export const downloadPdf = (data, filename) => {
  const blob = new Blob([data], { type: "application/pdf" });
  const url = window.URL.createObjectURL(blob);

  createAndClickDownloadLink(url, filename);
  window.URL.revokeObjectURL(url);
};

export const downloadBase64File = (uri, fileName) => {
  createAndClickDownloadLink(uri, fileName);
};

export const downloadExcel = (data, filename) => {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert data to worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Add worksheet to workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Create Blob and download
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);
  createAndClickDownloadLink(url, `${filename}.xlsx`);
  window.URL.revokeObjectURL(url);
};
