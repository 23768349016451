import axios from "axios";
import { useEffect, useState, useMemo } from "react";

const fetchAllCustomers = async ({
  pageSize = 20,
  currentPage = 1,
  query = "",
  sortField = "",
  sortOrder = "asc",
  vendor = "",
}) => {
  const response = await axios(
    `${process.env.REACT_APP_REST_API_ENDPOINT}/customers/statements?pageSize=${pageSize}&page=${currentPage}&query=${query}&sortField=${sortField}&sortOrder=${sortOrder}&invoiceTemplate=${vendor}`
  );
  return response.data;
};

export const useCustomersRest = ({
  currentPage = 1,
  pageSize = 20,
  query = "",
  sortField = "",
  sortOrder = "asc",
  vendor = "",
} = {}) => {
  const [customers, setCustomers] = useState([]);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [loading, setLoading] = useState(false);

  // Fetch all customers on mount
  useEffect(() => {
    console.log("query", query);
    setLoading(true);
    fetchAllCustomers({
      currentPage,
      pageSize,
      query,
      sortField,
      sortOrder,
      vendor,
    })
      .then((data) => {
        setCustomers(data.customers);
        setTotalCustomers(data.total);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setCustomers([]);
      setTotalCustomers(0);
    };
  }, [currentPage, pageSize, query, sortField, sortOrder, vendor]);

  return {
    customers,
    customersCount: totalCustomers,
    loading,
  };
};
