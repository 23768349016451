import { useAuth } from "@/hooks/useAuth";
import { calculateProfit, calculateProfitMargin, formatMoney } from "@/utils";
import {
  ExclamationCircleFilled,
  InfoCircleOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { Alert, Col, Divider, Row, Space, Tooltip, Typography } from "antd";
import styled from "styled-components";

const TotalSumBar = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

const SumLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ProjectionLines = ({ lineItems, total, totalBasePrice }) => {
  const profit = calculateProfit(total, totalBasePrice);
  const profitMargin = calculateProfitMargin(total, totalBasePrice);
  const hasLineItemsWithoutCost = lineItems.some((item) => !item.unitCost);
  return (
    <>
      <Divider />
      <SumLine>
        <Typography.Text strong>
          Projections{" "}
          {hasLineItemsWithoutCost && (
            <Tooltip title="Some line items have no cost, so profit and profit margin is not acurate.">
              <ExclamationCircleFilled />
            </Tooltip>
          )}
        </Typography.Text>
      </SumLine>
      <SumLine>
        <Typography.Text>Total Cost</Typography.Text>
        <Typography.Text strong>{formatMoney(totalBasePrice)}</Typography.Text>
      </SumLine>
      <SumLine>
        <Typography.Text>Est. Profit</Typography.Text>
        <Typography.Text strong>{formatMoney(profit)}</Typography.Text>
      </SumLine>
      <SumLine>
        <Typography.Text>Est. Profit margin</Typography.Text>
        <Typography.Text strong>{profitMargin}%</Typography.Text>
      </SumLine>
    </>
  );
};

export const OrderTotalSummary = ({
  total,
  totalWithoutGst,
  gstAmount,
  discountAmount,
  status,
  totalBasePrice,
  lineItems,
}) => {
  const { user } = useAuth();
  return (
    <Row>
      <Col span={16}>
        <Space>
          {status === "draft" && (
            <Alert showIcon message="Draft order's total is pending" type="warning" />
          )}
        </Space>
      </Col>
      <Col span={8}>
        <TotalSumBar>
          <SumLine>
            <Typography.Text>Subtotal</Typography.Text>
            <Typography.Text strong>$ {totalWithoutGst}</Typography.Text>
          </SumLine>
          <SumLine>
            <Typography.Text>GST</Typography.Text>
            <Typography.Text strong>$ {gstAmount}</Typography.Text>
          </SumLine>
          <SumLine>
            <Typography.Text>Discount</Typography.Text>
            <Typography.Text strong>$ {discountAmount}</Typography.Text>
          </SumLine>
          <SumLine>
            <Typography.Text>Total</Typography.Text>
            <Typography.Text strong>$ {total}</Typography.Text>
          </SumLine>
        </TotalSumBar>

        {["admin", "super_admin", "manager"].includes(user.role) && lineItems && totalBasePrice && (
          <ProjectionLines lineItems={lineItems} total={total} totalBasePrice={totalBasePrice} />
        )}
      </Col>
    </Row>
  );
};
