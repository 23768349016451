export const calculateProfit = (total, totalBasePrice) => {
  if (!total || !totalBasePrice) return 0;
  if (total === 0) return 0;
  const profit = total - totalBasePrice;
  return profit;
};

export const calculateProfitMargin = (total, totalBasePrice) => {
  if (total === 0) return 0;
  const profitMargin = ((total - totalBasePrice) / total) * 100;
  return profitMargin.toFixed(2);
};
