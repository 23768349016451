import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  Table,
  Button,
  PageHeader,
  Typography,
  notification,
  Statistic,
  Spin,
  Select,
  Space,
  Input,
} from "antd";
import { FileExcelOutlined, FilePdfOutlined, FileZipOutlined } from "@ant-design/icons";
import moment from "moment";

import { MoneyCell } from "../components/tablecells";
import { PageContainer } from "../components/atoms/PageContainer";
import { PageContentContainer } from "../components/atoms/PageContentContainer";

import { StatementSummaryPDF } from "../components/pdf/StatementSummaryPDF";
import { getDateColor, exportExcel } from "../utils";
import { formatMoney } from "../utils/index";
import { StatementAction } from "../components/StatementAction/index.js";
import { STATEMENT_SORT_OPTIONS, VendorsMap } from "../constants";
import { ToolbarWrapper } from "../components/atoms/ToolbarWrapper";
import { useCustomersRest } from "@/hooks/useCustomersRest";
import axios from "axios";
import { downloadExcel } from "@/utils/downloadFiles";

const pageSize = 20;

const calcTotalBalance = (customers) =>
  customers.reduce((prev, current) => prev + parseFloat(current.accountBalance || "0"), 0);

export const Statements = () => {
  const [query, setQuery] = useState("");
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [vendor, setVendor] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { customers, customersCount, loading } = useCustomersRest({
    query,
    currentPage,
    pageSize,
    sortField,
    sortOrder,
    vendor,
  });

  const [sumOfBalance, setSumOfBalance] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedTotal, setSelectedTotal] = useState(0);

  const columns = [
    { title: "#", dataIndex: "customerCode", key: "customerCode" },
    { title: "Customer", dataIndex: "name", key: "name" },
    {
      title: "Terms",
      dataIndex: "paymentTerms",
      key: "paymentTerms",
    },
    {
      title: "Method",
      dataIndex: "statementMethod",
      key: "statemenMethod",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "First unpaid order",
      dataIndex: "firstUnpaidOrder",
      key: "firstUnpaidOrder",
      render: (_, record) => {
        if (record.orders?.length) {
          const ordersOnly = record.orders.filter((o) => !o.isCreditNote);
          const firstUnpaidOrder = ordersOnly[0];
          return firstUnpaidOrder ? (
            <Typography.Text type={getDateColor(firstUnpaidOrder?.orderDate)}>
              {moment(firstUnpaidOrder?.orderDate).format("DD/MM/YYYY")}
            </Typography.Text>
          ) : null;
        } else {
          return "";
        }
      },
    },
    {
      title: "Latest unpaid order",
      dataIndex: "latestUnpaidOrder",
      key: "latestUnpaidOrder",
      render: (_, record) => {
        if (record.orders?.length) {
          const ordersOnly = record.orders.filter((o) => !o.isCreditNote);
          const latestUnpaidOrder = ordersOnly[ordersOnly.length - 1];
          return latestUnpaidOrder ? (
            <Typography.Text type={getDateColor(latestUnpaidOrder?.orderDate)}>
              {moment(latestUnpaidOrder?.orderDate).format("DD/MM/YYYY")}
            </Typography.Text>
          ) : null;
        } else {
          return "";
        }
      },
    },
    {
      title: "Latest payment",
      dataIndex: "latestPayment",
      key: "latestPayment",
      render: (_, record) => {
        if (record.paymentBatches?.length) {
          return (
            <Typography.Text type={getDateColor(record.paymentBatches[0]?.receivedDate)}>
              {moment(record.paymentBatches[0]?.receivedDate).format("DD/MM/YYYY")}
            </Typography.Text>
          );
        } else {
          return "";
        }
      },
    },
    {
      title: "Salesperson",
      dataIndex: ["salesperson", "name"],
      key: "salesperson",
    },

    {
      title: "Franchise",
      dataIndex: ["franchise", "name"],
      key: "franchise",
    },
    {
      title: "Vendor",
      dataIndex: "invoiceTemplate",
      key: "vendor",
      render: (value) => VendorsMap[value].label,
    },
    {
      title: "Account balance",
      dataIndex: "accountBalance",
      key: "accountBalance",
      ...MoneyCell,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      excludeInExport: true,
      render: (_, record) => <StatementAction record={record} />,
    },
  ];

  const rowSelection = {
    checkStrictly: false,
    selectedRowKeys,
    onChange: (newSelectedRowKeys, selectedRows) => {
      setSelectedTotal(calcTotalBalance(selectedRows));
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleExcelExport = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_REST_API_ENDPOINT}/customers/statement-report`
    );

    downloadExcel(res.data, `Summary_as_of_${moment().format("DD/MM/YYYY")}.xlsx`);
  };

  const handleBatchDownload = async () => {
    console.log("Batch download started");
    if (selectedRowKeys.length === 0) {
      return;
    }

    for (const customerId of selectedRowKeys) {
      const customerElement = document.getElementById(customerId);
      if (customerElement) {
        customerElement.click();
        // Wait 500ms before processing the next element
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    }
  };
  const sumFooter = () => {
    return (
      <pre
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
          fontSize: "20px",
          fontWeight: "600",
          textAlign: "end",
        }}
      >
        {`Selected Total:${formatMoney(selectedTotal)}`}
      </pre>
    );
  };

  const handleSearch = (value) => {
    setQuery(value);
    setCurrentPage(1);
  };

  const handleVendorFilterChange = (value) => {
    setVendor(value);
    setCurrentPage(1);
  };

  const handleSortChange = (value) => {
    setCurrentPage(1);
    switch (value) {
      case "nameAsc":
        setSortField("name");
        setSortOrder("asc");
        break;
      case "nameDesc":
        setSortField("name");
        setSortOrder("desc");
        break;
      case "lastPaymentDesc":
        setSortField("lastPayment");
        setSortOrder("desc");
        break;
      case "lastPaymentAsc":
        setSortField("lastPayment");
        setSortOrder("asc");
        break;
      default:
        setSortField("name");
        setSortOrder("asc");
    }
  };

  return (
    <PageContainer>
      <PageHeader
        title={`${moment().format("DD/MM/YYYY")} · Statements`}
        subTitle={`showing ${customers.length} of ${customersCount} customers`}
        extra={[
          <Select
            key="select"
            defaultValue={"nameAsc"}
            onChange={handleSortChange}
            options={STATEMENT_SORT_OPTIONS}
            style={{
              width: 320,
            }}
          />,
          loading ? (
            <Spin key="sum-spin" />
          ) : (
            <Statistic value={formatMoney(sumOfBalance)} key="sum" />
          ),
          <Button
            key="export-excel"
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={handleExcelExport}
            loading={loading}
          >
            Export Excel
          </Button>,
          // <PDFDownloadLink
          //   key="export-pdf"
          //   document={<StatementSummaryPDF data={customers} total={sumOfBalance} />}
          //   fileName={`Summary_as_of_${moment().format("DD/MM/YYYY")}.pdf`}
          // >
          //   {({ isPdfLoading, isPdfError }) => (
          //     <Button
          //       type="primary"
          //       disabled={isPdfError}
          //       icon={<FilePdfOutlined />}
          //       loading={isPdfLoading}
          //     >
          //       Export PDF
          //     </Button>
          //   )}
          // </PDFDownloadLink>,
          <Button
            key="batch-download"
            icon={<FileZipOutlined />}
            onClick={handleBatchDownload}
            disabled={selectedRowKeys.length === 0}
          >
            Batch Download
          </Button>,
        ]}
      />
      <PageContentContainer>
        <ToolbarWrapper>
          <Space>
            <Input.Search
              placeholder="Search name or code"
              enterButton
              allowClear
              onSearch={handleSearch}
              style={{ width: 320 }}
            />
            <Space>
              <label>Vendor:</label>
              <Select
                defaultValue="ALL"
                style={{ width: 200 }}
                onChange={handleVendorFilterChange}
                options={[
                  {
                    value: "",
                    label: "All",
                  },
                  {
                    value: "NEWNORTH",
                    label: "Newnorth",
                  },
                  {
                    value: "GLORY_FRESH",
                    label: "Glory Fresh",
                  },
                  {
                    value: "EVER_FRESH",
                    label: "Ever Fresh",
                  },
                ]}
              />
            </Space>
          </Space>
        </ToolbarWrapper>
        <Table
          dataSource={customers}
          columns={columns}
          rowKey="id"
          size="small"
          bordered
          loading={loading}
          pagination={{
            current: currentPage,
            total: customersCount,
            pageSize: pageSize,
            showSizeChanger: false,
            onChange: (currentPageIndex) => {
              setCurrentPage(currentPageIndex);
            },
          }}
          footer={sumFooter}
          rowSelection={rowSelection}
        />
      </PageContentContainer>
    </PageContainer>
  );
};
