import { usePDF } from "@react-pdf/renderer";
import { Button, Space } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import moment from "moment";

import { SendEmailModal } from "../SendEmailModal";
import { CustomerStatementPDF } from "../pdf/CustomerStatementPDF";

export const StatementAction = ({ record }) => {
  const [statementPdfInstance] = usePDF({
    document: <CustomerStatementPDF data={record} />,
  });

  // useEffect(() => {
  //   if (record?.email.length > 0 && statementPdfInstance) {
  //     updateStatementPdfInstance();
  //   }
  // }, [record, updateStatementPdfInstance]);

  return (
    <Space>
      <a
        id={record.id}
        href={statementPdfInstance.url}
        target="_blank"
        rel="noreferrer"
        download={`${record.name}_${record.customerCode}_as_of_${moment().format(
          "DD/MM/YYYY"
        )}.pdf`}
      >
        <Button
          disabled={statementPdfInstance.loading || statementPdfInstance.error}
          loading={statementPdfInstance.loading}
          icon={<FilePdfOutlined />}
          size="small"
        >
          PDF
        </Button>
      </a>
      {/* <SendEmailModal
        size="small"
        buttonTitle="Email"
        modalTitle="Send statement"
        content="statement"
        customer={record}
        blob={statementPdfInstance.blob}
        disabled={statementPdfInstance.loading || statementPdfInstance.error}
        loading={statementPdfInstance.loading}
      /> */}
    </Space>
  );
};
