import { Row, Col, Card, Typography, Button, Space, Divider } from "antd";
import { Link } from "react-router-dom";
import { CarOutlined, FileAddOutlined, SnippetsOutlined, UserAddOutlined } from "@ant-design/icons";
import { useAuth } from "@/hooks/useAuth";

const homepageQuickLinks = [
  {
    label: "New order",
    icon: <FileAddOutlined style={{ fontSize: 30 }} />,
    link: "/orders/v2/new",
    visibility: ["admin", "super_admin", "manager", "staff"],
    context: ["operation"],
  },
  {
    label: "All orders",
    icon: <SnippetsOutlined style={{ fontSize: 30 }} />,
    link: "/orders",
    visibility: ["admin", "super_admin", "manager", "staff"],
    context: ["operation"],
  },
  {
    label: "Add a customer",
    icon: <UserAddOutlined style={{ fontSize: 30 }} />,
    link: "/customers/new",
    visibility: ["admin", "super_admin", "manager", "staff"],
    context: ["operation"],
  },
  {
    label: "Logistic",
    icon: <CarOutlined style={{ fontSize: 30 }} />,
    link: "/logistic",
    visibility: ["admin", "super_admin", "manager"],
    context: ["operation"],
  },
];

const CONTEXT = process.env.REACT_APP_USER_CONTEXT;

export const HomePage = () => {
  const { user } = useAuth();
  return (
    <>
      <Typography.Title level={4}>Welcome, {user?.name}</Typography.Title>
      <Typography.Text type="secondary">{user?.organisations[0]?.name}</Typography.Text>
      <Divider />
      <Row gutter={16}>
        {homepageQuickLinks.map(
          (link) =>
            link.visibility.includes(user.role) &&
            link.context.includes(CONTEXT) && (
              <Col span={6}>
                <Card hoverable>
                  <Link to={link.link}>
                    <Space>
                      {link.icon}
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        {link.label}
                      </Typography.Title>
                    </Space>
                  </Link>
                </Card>
              </Col>
            )
        )}
      </Row>
    </>
  );
};
