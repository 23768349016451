import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import _ from "lodash";

import { useQuery } from "@apollo/client";
import { OneDayOrdersQuery } from "../graphql/orders.graphql";
import { DeliveryRunQuery } from "../graphql/deliveryRun.graphql";
import { TruckColumn } from "../components/Logistic/TruckColumn";
import { useSetDeliveryRun } from "../hooks/useSetDeliveryRun";
import { useDebounce } from "../hooks/useDebounce";

import { RUNSHEET_DEFAULT_VALUE } from "../constants";
import { Button, PageHeader, Typography, Spin } from "antd";
import { PrinterFilled } from "@ant-design/icons";
import { DragDropContext } from "react-beautiful-dnd";

import { usePDF } from "@react-pdf/renderer";
import { RunsheetPDF } from "../components/pdf/RunsheetPDF";

const BoardContainer = styled.div`
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-columns: repeat(10, 1fr);
`;

const endOfDay = moment().endOf("day").toISOString();
const startOfDay = moment().startOf("day").toISOString();
const date = String(moment().format("YYYY-MM-DD"));

export const LogisticHome = () => {
  const [emptyDeliveryRun, setEmptyDeliveryRun] = useState(true);
  const [orders, setOrders] = useState({});
  const [trucks, setTrucks] = useState({});
  const [deliveryRun, setDeliveryRun] = useState({});

  const { data: orderData, refetch } = useQuery(OneDayOrdersQuery, {
    variables: { end: endOfDay, start: startOfDay },
    fetchPolicy: "network-only",
  });
  const { data: deliveryRunData } = useQuery(DeliveryRunQuery, {
    variables: { name: date },
    fetchPolicy: "network-only",
  });

  const { doSetDeliveryRun, createLoading, updateLoading } = useSetDeliveryRun(emptyDeliveryRun);
  const [tempData, setTempData] = useState();
  const debouncedData = useDebounce(tempData, 1000);
  // const [runsheetPDFInstance, updateRunsheetPDFInstance] = usePDF({
  //   document: <RunsheetPDF trucks={Object.values(trucks)} allOrders={orders} />,
  // });

  // useEffect(() => {
  //   if (Object.keys(trucks).length && Object.keys(orders).length) {
  //     updateRunsheetPDFInstance();
  //   }

  //   // eslint-disable-next-line
  // }, [trucks, orders]);

  useEffect(() => {
    if (tempData) {
      const asyncMutation = async () => {
        const res = await doSetDeliveryRun({ runsheet: Object.values(tempData) });
        setEmptyDeliveryRun(false);
        console.log("res: ", res);
      };
      asyncMutation();
    }
    // eslint-disable-next-line
  }, [debouncedData]);

  useEffect(() => {
    if (deliveryRunData) {
      // Initiate deliveryRun
      if (deliveryRunData.deliveryRun) {
        setEmptyDeliveryRun(false);
        setDeliveryRun(deliveryRunData.deliveryRun);
      } else {
        setDeliveryRun({ name: date, runsheet: RUNSHEET_DEFAULT_VALUE });
      }
    }
  }, [deliveryRunData]);

  useEffect(() => {
    if (deliveryRun.runsheet && orderData?.orders) {
      const trucksList = deliveryRun.runsheet.reduce((prev, current) => {
        prev[current.truckName] = {
          truckName: current.truckName,
          driverName: current.driverName,
          plateNumber: current.plateNumber,
          orderIds: current.orderIds,
        };
        return prev;
      }, {});

      // deal with orders data //
      const allocatedOrders = deliveryRun.runsheet.reduce(
        (prev, current) => [...prev, ...current.orderIds],
        []
      );
      const results = orderData.orders.reduce(
        (prev, current) => {
          if (!allocatedOrders.includes(current.id)) {
            console.log("current: ", current);
            prev.allOrders[current.id] = { id: current.id, isUnallocated: true, content: current };
            // unallocated order put into truck using customer's defaultRunName
            if (current.customer.defaultRunName) {
              trucksList[current.customer.defaultRunName].orderIds.push(current.id);
            } else {
              trucksList["R1-T10"].orderIds.push(current.id);
            }
          } else {
            prev.allOrders[current.id] = { id: current.id, content: current };
          }
          return prev;
        },
        { allOrders: {} }
      );
      setOrders(results.allOrders);

      setTrucks(trucksList);
    }
  }, [deliveryRun, orderData]);

  const handleRefresh = () => {
    console.log("Refreshing...");
    refetch();
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    } else {
      const startTruck = trucks[source.droppableId];
      let updatedtrucks = {};

      // Moved an order in the same truck
      if (source.droppableId === destination.droppableId) {
        const newOrders = Array.from(startTruck.orderIds);
        newOrders.splice(source.index, 1);
        newOrders.splice(destination.index, 0, draggableId);
        const newTruck = {
          ...startTruck,
          orderIds: newOrders,
        };
        updatedtrucks = {
          ...trucks,
          [newTruck.truckName]: newTruck,
        };
      }
      //Moved an order from one truck to another truck
      else {
        const finishTruck = trucks[destination.droppableId];
        const startTruckOrders = Array.from(startTruck.orderIds);
        startTruckOrders.splice(source.index, 1);
        const newStartTruck = {
          ...startTruck,
          orderIds: startTruckOrders,
        };
        const finishTruckOrders = Array.from(finishTruck.orderIds);
        finishTruckOrders.splice(destination.index, 0, draggableId);
        const newFinishTruck = {
          ...finishTruck,
          orderIds: finishTruckOrders,
        };
        updatedtrucks = {
          ...trucks,
          [newStartTruck.truckName]: newStartTruck,
          [newFinishTruck.truckName]: newFinishTruck,
        };
      }
      setTrucks(updatedtrucks);
      const copiedTrucks = _.cloneDeep(updatedtrucks);
      delete copiedTrucks.Unallocated;

      setTempData(copiedTrucks);
    }
  };

  return (
    <div>
      <PageHeader
        title={`Logistic ${date}`}
        subTitle={`${orderData?.orders.length} orders`}
        extra={[
          // <a href={runsheetPDFInstance.url} key="print-today" rel="noreferrer" target="_blank">
          //   <Button icon={<PrinterFilled />} loading={runsheetPDFInstance.loading}>
          //     Print
          //   </Button>
          // </a>,
          <Button key="refresh" onClick={handleRefresh}>
            Refresh
          </Button>,
          <div style={{ width: "50px" }} key="loading-indicator">
            {(createLoading || updateLoading) && (
              <Typography>
                <Spin /> Loading
              </Typography>
            )}
          </div>,
        ]}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <BoardContainer>
          {trucks &&
            Object.keys(trucks).map((truckName) => {
              const orderList = trucks[truckName].orderIds.reduce((pre, cur) => {
                if (orders[cur]) {
                  pre.push(orders[cur]);
                }
                return pre;
              }, []);

              return (
                <TruckColumn
                  key={truckName}
                  trucks={trucks}
                  setTrucks={setTrucks}
                  setTempData={setTempData}
                  truckName={truckName}
                  orderList={orderList}
                  emptyDeliveryRun={emptyDeliveryRun}
                  setEmptyDeliveryRun={setEmptyDeliveryRun}
                  orders={orders}
                />
              );
            })}
        </BoardContainer>
      </DragDropContext>
    </div>
  );
};
