import { Page, Document, Font } from "@react-pdf/renderer";
import { Row, Typography, PageNumber, HorizontalLine } from "./utilities";
import moment from "moment-timezone";

Font.register({
  family: "Microsoft YaHei",
  src: `${process.env.PUBLIC_URL}/fonts/chinese.msyh.ttf`,
});

const RunsheetPage = ({ driverName, platenumber, name, orders, allOrders }) => {
  return (
    <Page
      size="A4"
      style={{
        paddingTop: 32,
        paddingBottom: 48,
        paddingHorizontal: 32,
        backgroundColor: "#fff",
        fontFamily: "Microsoft YaHei",
      }}
    >
      <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
        {moment().format("YYYY-MM-DD")} {name}
      </Typography>

      <Typography type="header" sx={{ fontSize: "24px", textAlign: "right" }}>
        {driverName}, Truck: {platenumber}, Total orders: {orders.length}
      </Typography>
      <HorizontalLine />
      {orders.length ? (
        orders.map((orderId, idx) => {
          if (allOrders[orderId]) {
            return (
              <Row sx={{ borderBottom: "1px solid black" }} key={`${orderId}-${idx}`}>
                <Typography
                  type="header"
                  sx={{ padding: "2px 4px", width: "20%", borderLeft: "1px solid black" }}
                >
                  {allOrders[orderId]?.content.name}
                </Typography>
                <Typography
                  type="header"
                  sx={{
                    padding: "2px 4px",
                    width: "80%",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    fontFamily: "Microsoft YaHei",
                  }}
                >
                  {allOrders[orderId]?.content.customer.name}
                </Typography>
                <Typography
                  type="header"
                  sx={{ padding: "2px 4px", width: "40%", borderRight: "1px solid black" }}
                ></Typography>
              </Row>
            );
          } else {
            return null;
          }
        })
      ) : (
        <Typography>No orders</Typography>
      )}

      <PageNumber />
    </Page>
  );
};

export const RunsheetPDF = ({ trucks = [], allOrders = {} }) => {
  return (
    <Document>
      {trucks?.length &&
        trucks.map((truck, idx) => {
          return (
            <RunsheetPage
              key={idx}
              driverName={truck?.driverName}
              platenumber={truck?.plateNumber}
              name={truck?.name}
              orders={truck?.orderIds || []}
              allOrders={allOrders}
            />
          );
        })}
    </Document>
  );
};
