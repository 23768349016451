import { Space, Avatar, Typography, Card } from "antd";
import { VendorInfo } from "../atoms/VendorInfo";
import { Link } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
export const CustomerDetailCard = ({ customer = { name: "" }, footer, extra, linkToCustomer }) => {
  const { id, name, email, phone, deliveryAddress, invoiceTemplate } = customer;
  return (
    <Card title="Customer" actions={footer ? [footer] : null} extra={extra}>
      <Space direction="vertical">
        <Space>
          <Avatar>{customer.name?.charAt(0).toUpperCase()}</Avatar>
          <Typography.Title style={{ margin: 0 }} level={5}>
            {linkToCustomer ? (
              <Link to={`/customers/${id}`}>
                {name} <LinkOutlined />
              </Link>
            ) : (
              name
            )}
          </Typography.Title>
        </Space>
        {customer.franchise && (
          <Typography.Text>Franchise: {customer.franchise?.name}</Typography.Text>
        )}
        <Typography.Text>Email: {email}</Typography.Text>
        <Typography.Text>Phone: {phone}</Typography.Text>
        <Typography.Text>Address: {deliveryAddress}</Typography.Text>
        <VendorInfo vendor={invoiceTemplate} />
      </Space>
    </Card>
  );
};
