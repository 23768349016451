import { VendorsMap } from "@/constants";
import { Space, Typography } from "antd";

export const VendorInfo = ({ vendor }) => {
  if (!vendor) return null;
  return (
    <Space align="center">
      <img
        style={{
          height: "32px",
          width: "auto",
          objectFit: "cover",
        }}
        alt="logo"
        src={VendorsMap[vendor].imgSrc}
      />
      <Typography.Text strong>{VendorsMap[vendor].label}</Typography.Text>
    </Space>
  );
};
