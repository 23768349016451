import moment from "moment";
import { Card, Space, Typography, Divider } from "antd";
import { CustomerDetailCard } from "../CustomerDetailCard";
export const OrderInfoCard = ({ customer, order }) => {
  const {
    fulfillmentType,
    deliveryAddress,
    notes,
    packerNotes,
    updatedAt,
    createdAt,
    orderDate,
    confirmedAt,
  } = order;

  return (
    <>
      <CustomerDetailCard customer={customer} linkToCustomer={true} />
      <Divider />
      <Card title="Addtional Info">
        <Space>
          <Space direction="vertical">
            <Typography.Text strong> Date</Typography.Text>
            <Typography.Text>{moment(orderDate).format("DD/MM/YYYY")}</Typography.Text>
          </Space>
          <Divider type="vertical" />
          <Space direction="vertical">
            <Typography.Text strong>Fulfillment Type</Typography.Text>
            <Typography.Text>{fulfillmentType}</Typography.Text>
          </Space>
        </Space>
        <Divider />

        <Space direction="vertical">
          <Typography.Text strong>Shipping address</Typography.Text>
          <Typography.Text>{deliveryAddress}</Typography.Text>
        </Space>

        <Divider />

        <Space direction="vertical">
          <Typography.Text strong>Notes</Typography.Text>
          <Typography.Text>{notes}</Typography.Text>
        </Space>
        <Divider />
        <Space direction="vertical">
          <Typography.Text strong>Packer notes</Typography.Text>
          <Typography.Text>{packerNotes}</Typography.Text>
        </Space>
      </Card>
      <Divider />
      <Card title="Timestamps">
        <Space direction="vertical">
          <Space>
            <Typography.Text strong>Created at: </Typography.Text>
            <Typography.Text>{moment(createdAt).format("YYYY-MM-DD, HH:mm:ss")}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Confirmed at: </Typography.Text>
            <Typography.Text>
              {confirmedAt ? moment(confirmedAt).format("YYYY-MM-DD, HH:mm:ss") : "not confirmed"}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text strong>Updated at: </Typography.Text>
            <Typography.Text>{moment(updatedAt).format("YYYY-MM-DD, HH:mm:ss")}</Typography.Text>
          </Space>
        </Space>
      </Card>
    </>
  );
};
