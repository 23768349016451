import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";

import { useLocation } from "react-router-dom";

import { SiderTrigger } from "../../../components/SiderTrigger";
import { useAuth } from "../../../hooks/useAuth";
import { menuItems, salesColumns } from "./menuItems";

const isDevMode = process.env.NODE_ENV === "development";
const context = process.env.REACT_APP_USER_CONTEXT || "operation";

export const Sidebar = ({ collapsed, setCollapsed }) => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState(["/"]);
  const { user } = useAuth();
  const [items, setItems] = useState([]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (user?.role === "sales") {
      setItems(salesColumns);
      return;
    }
    if (user) {
      const menuItemsFiltered = menuItems.filter((item) => {
        return item.visibility.includes(user?.role) && item.context.includes(context);
      });
      setItems(menuItemsFiltered);
    }
  }, [user]);

  useEffect(() => {
    if (typeof window !== "undefined" && window.visualViewport.width <= 768) {
      setCollapsed(true);
    }
  }, [setCollapsed]);

  useEffect(() => {
    const { pathname } = location;

    if (pathname === "/") {
      setSelectedKeys(["home"]);
    } else {
      if (pathname === "/payments/new") {
        setSelectedKeys(["/payments/new"]);
      } else {
        const prefix = pathname.split("/")[1];
        setSelectedKeys([prefix]);
      }
    }
  }, [location]);

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={handleCollapse}
      width={collapsed ? "64px" : "156px"}
      style={{
        overflow: "auto",
        height: "calc(100% - 3.5rem)",
        position: "fixed",
        left: 0,
        top: "3.5rem",
        bottom: 0,
        background: "#fff",
        borderRight: "0.33px solid lightgrey",
      }}
      theme="light"
      trigger={<SiderTrigger collapsed={collapsed} />}
    >
      <Menu items={items} selectedKeys={selectedKeys} mode="inline" />
    </Layout.Sider>
  );
};
